:root {
  --blue: #2c70c8;
  --navy: #414c6b;
  --mint: #5baeb7;
  --sky: #a5def2;
}

body,html {
  color: var(--navy) !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding: 0px !important;
  position: relative;
  overflow-x: hidden !important;

  a{
    color: #8ad3db;
    font-weight: 500;
    text-decoration: none;

    &:hover{
      color: #8ad3db;
      font-weight: 700;
      text-decoration: underline;
    }
    
  }

  .inline-call{
    a{color: var(--mint) !important;}
  }
  .highlight{
    font-weight: 700;
    color: var(--mint);
  }
  .branding{
    font-weight: 600;
    font-style: italic;
  }
  .collapse-button{
    background-color: transparent;
    color: var(--blue);
    border:1px solid #414c6b;

    &:hover, &:active {
      background-color: var(--navy);
      border:1px solid transparent;
      color: white;

    }
    

  }


  // HEADERS 


  .header {
    color: var(--mint);
    font-size: 2rem;
  }
// LANDING
  .text-overlay {
    background: rgba($color: rgb(45, 45, 66), $alpha: 0.5);
    padding: 40px;
    border-radius: 10px;
    margin-top: 90px;

    @media (max-width: 700px) {
      padding: 10px;
      margin-top: 0px;
    }
  }

  .landing-img {
    height: 350px;
    @media (min-width: 700px) {
      height: 500px;
    }
  }
  .desc {
    font-style: italic;
  }
  .button-call {  
/* CSS */

  background: var(--mint);
  border: 1px solid var(--mint);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;


&:hover,
&:active {
  background-color: initial;
  background-position: 0 0;
  color: white;
  border-color: var(--mint) !important;
}

&:active {
  opacity: .5;
}
    }
  }
  .subheader {
    color: var(--blue) !important;
  }


// Process
.icons {
  color: var(--blue);
font-size: 3rem;
/* padding: 20px; */
width: -moz-fit-content;
/* width: fit-content; */
margin: 10px auto;

}
.process-card{
  border-radius: 10px !important;
  &:hover, &:focus{
    background-color: #7e8597;
    color: white;
    transition: .4s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    transform: translateY(-1px);
    transition: all 250ms;

    .icons{
      color: white;
    }
  }}


// Call Button
.phone-icon{
  padding: 10px;
  background-color: var(--mint);
  border-radius: 50%;
  font-size: 1.5rem;
  color: white;
  position: fixed;
  bottom: 50px;
  right: 40px;

  &:hover{
    // background-color: rgb(170, 221, 204);
    // background-color: var(--blue);
    background-color: #7e8597;

    transition: .7s;

    cursor: pointer;
  }

}

// Footer

.footer {
  color: white;
  width: 100%;
  padding: 0px !important;
  position: absolute;
  bottom: 0;
  font-size: .8rem;
}
