:root {
  --blue: #2c70c8;
  --navy: #414c6b;
  --mint: #5baeb7;
  --sky: #a5def2;
}

body, html {
  color: var(--navy) !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding: 0px !important;
  position: relative;
  overflow-x: hidden !important;
}

body a, html a {
  color: #8ad3db;
  font-weight: 500;
  text-decoration: none;
}

body a:hover, html a:hover {
  color: #8ad3db;
  font-weight: 700;
  text-decoration: underline;
}

body .inline-call a, html .inline-call a {
  color: var(--mint) !important;
}

body .highlight, html .highlight {
  font-weight: 700;
  color: var(--mint);
}

body .branding, html .branding {
  font-weight: 600;
  font-style: italic;
}

body .collapse-button, html .collapse-button {
  background-color: transparent;
  color: var(--blue);
  border: 1px solid #414c6b;
}

body .collapse-button:hover, body .collapse-button:active, html .collapse-button:hover, html .collapse-button:active {
  background-color: var(--navy);
  border: 1px solid transparent;
  color: white;
}

body .header, html .header {
  color: var(--mint);
  font-size: 2rem;
}

body .text-overlay, html .text-overlay {
  background: rgba(45, 45, 66, 0.5);
  padding: 40px;
  border-radius: 10px;
  margin-top: 90px;
}

@media (max-width: 700px) {
  body .text-overlay, html .text-overlay {
    padding: 10px;
    margin-top: 0px;
  }
}

body .landing-img, html .landing-img {
  height: 350px;
}

@media (min-width: 700px) {
  body .landing-img, html .landing-img {
    height: 500px;
  }
}

body .desc, html .desc {
  font-style: italic;
}

body .button-call, html .button-call {
  /* CSS */
  background: var(--mint);
  border: 1px solid var(--mint);
  border-radius: 6px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
          box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  vertical-align: middle;
}

body .button-call:hover, body .button-call:active, html .button-call:hover, html .button-call:active {
  background-color: initial;
  background-position: 0 0;
  color: white;
  border-color: var(--mint) !important;
}

body .button-call:active, html .button-call:active {
  opacity: .5;
}

.subheader {
  color: var(--blue) !important;
}

.icons {
  color: var(--blue);
  font-size: 3rem;
  /* padding: 20px; */
  width: -moz-fit-content;
  /* width: fit-content; */
  margin: 10px auto;
}

.process-card {
  border-radius: 10px !important;
}

.process-card:hover, .process-card:focus {
  background-color: #7e8597;
  color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border: none;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
          box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  -webkit-transition: all 250ms;
  transition: all 250ms;
}

.process-card:hover .icons, .process-card:focus .icons {
  color: white;
}

.phone-icon {
  padding: 10px;
  background-color: var(--mint);
  border-radius: 50%;
  font-size: 1.5rem;
  color: white;
  position: fixed;
  bottom: 50px;
  right: 40px;
}

.phone-icon:hover {
  background-color: #7e8597;
  -webkit-transition: .7s;
  transition: .7s;
  cursor: pointer;
}

.footer {
  color: white;
  width: 100%;
  padding: 0px !important;
  position: absolute;
  bottom: 0;
  font-size: .8rem;
}
