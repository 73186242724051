.Ad {
  background-color: var(--mint);
  color: var(--navy);
  padding: 2px 0px;
  color: white;
  font-size: 15px;
}

.Ad p {
  margin: 0 !important;
}

nav {
  margin: 0px;
  background-color: #3e4160;
}

nav img {
  margin: 10px 20px;
  border-right: 4px solid white;
}
